$defaultText: rgba(0,0,0,0.75);
$primaryDark: #002146;
$primaryLight: #0074F7;
$primaryWhite: rgba(255,255,255,0.9);
$primaryDisabled: rgba(0,0,0,0.1);

$errorColor: #ffbbbb;
$successColor: #ACF5AF;

$borderRadius: 4px;

$spinnerColor: $primaryDisabled;
